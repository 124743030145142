<template>
  <div class="pt-10">
    <div class="vx-col w-auto">
      <vx-card>
        <div class="flex items-center justify-between mb-10">
          <div class="vx-breadcrumb md:block hidden">
            <ul class="flex flex-wrap items-center">
              <li class="inline-flex items-center">
                <router-link :to="{ name: 'director-learning-centre-list'}">Manage</router-link>
                <span class="breadcrumb-separator mx-2">
                  <span class="feather-icon select-none relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-chevrons-right"
                    >
                      <polyline points="13 17 18 12 13 7"/>
                    </svg>
                  </span>
                </span>
              </li>
              <li class="inline-flex items-center">
                <router-link
                  :to="{ name: 'director-learning-centre-view-list', params:{ type:'teachers', id: learningCenterId }}"
                >Teachers
                </router-link>
                <span class="breadcrumb-separator mx-2">
                  <span class="feather-icon select-none relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-chevrons-right"
                    >
                      <polyline points="13 17 18 12 13 7"/>
                    </svg>
                  </span>
                </span>
              </li>

              <li class="inline-flex items-center">
                <a href="javascript:void(0)" class>{{ fullName }}</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="w-full md:w-1/2">
          <div class="vx-col w-full mt-5 vs-con-loading__container">
            <template v-if="logo">
              <!-- Image Container -->
              <div class="img-container w-64">
                <img :src="logo" alt="img" class="responsive"/>
              </div>

              <!-- Image upload Buttons -->
              <div class="modify-img flex my-8">
                <input
                  type="file"
                  class="hidden"
                  ref="updateImgInput"
                  @change="updateCurrImg"
                  accept="image/*"
                />
                <vs-button
                  type="border"
                  class="mr-4 btn-blue-border"
                  @click="$refs.updateImgInput.click()"
                >Update Photo
                </vs-button>
                <vs-button color="danger" @click="logo = null">Remove Photo</vs-button>
              </div>
            </template>

            <div class="upload-img my-8" v-if="!logo">
              <input
                type="file"
                class="hidden"
                ref="uploadImgInput"
                @change="updateCurrImg"
                accept="image/*"
              />
              <vs-button
                type="border"
                class="mr-4"
                @click="$refs.uploadImgInput.click()"
              >Upload Photo
              </vs-button>
            </div>
          </div>
          <!-- Room -->
          <div class="vx-col w-full mt-5">
            <div class="select-wrapper">
              <div class="vs-component is-label-placeholder">
                <!-- <label class="vs-input--label">Room</label> -->
                <v-select
                  :options="roomOptions"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="roomFilter"
                  class="mb-4 md:mb-0"
                >
                  <template #header>
                    <label for class="label">Room</label>
                  </template>
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <vs-icon icon="arrow_drop_down"></vs-icon>
                    </span>
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <div class="vx-col w-full mt-5">

            <ul class="centerx">
              <li style="display: inline-block; margin-right: 10px">
                <vs-radio v-model="role" vs-value="teacher">Teacher</vs-radio>
              </li>
              <li style="display: inline-block">
                <vs-radio v-model="role" vs-value="leadTeacher">Lead Teacher</vs-radio>
              </li>
            </ul>
          </div>
          <!-- Title -->
          <div class="vx-col w-full mt-5">
            <div class="select-wrapper">
              <div class="vs-component is-label-placeholder">
                <v-select
                  :options="titleOptions"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="titleFilter"
                  class="mb-4 md:mb-0"
                  data-vv-as="Title"
                >
                  <template #header>
                    <label for class="label">Title</label>
                  </template>
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <vs-icon icon="arrow_drop_down"></vs-icon>
                    </span>
                  </template>
                </v-select>
              </div>
            </div>
          </div>

          <vs-input
            label-placeholder="Full Name"
            v-model="fullName"
            class="mt-5 w-full"
            name="fullName"
            v-validate="'required'"
            data-vv-as="Full Name"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('fullName')"
          >{{ errors.first('fullName') }}</span>

          <vs-input
            class="w-full mt-5"
            v-model="email"
            name="email"
            v-validate="'required|email'"
            label-placeholder="Email"
            data-vv-as="Email"
          />
          <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first('email') }}</span>

          <vs-input
            label-placeholder="Mobile Number"
            v-model="contactNumber"
            class="mt-5 w-full"
            name="contactNumber"

            data-vv-as="Mobile Number"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('contactNumber')"
          >{{ errors.first('contactNumber') }}</span>
          <div class="vx-col w-full mt-5">
            <div class="select-wrapper">
              <div class="vs-component is-label-placeholder">
                <v-select
                  :options="countryOptions"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="countryFilter"
                  class="mb-4 md:mb-0"
                  data-vv-as="Country"
                >
                  <template #header>
                    <label for class="label">Country</label>
                  </template>
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <vs-icon icon="arrow_drop_down"></vs-icon>
                    </span>
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <div class="vx-col w-full mt-5">
            <div class="select-wrapper">
              <div class="vs-component is-label-placeholder">
                <v-select
                  :options="stateOptions"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="stateFilter"
                  class="mb-4 md:mb-0"
                  data-vv-as="State"
                >
                  <template #header>
                    <label for class="label">State</label>
                  </template>
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <vs-icon icon="arrow_drop_down"></vs-icon>
                    </span>
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <vs-input
            label-placeholder="Street Address"
            v-model="streetAddress"
            class="mt-5 w-full"
            name="streetAddress"

            data-vv-as="Street Address"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('streetAddress')"
          >{{ errors.first('streetAddress') }}</span>

          <vs-input
            label-placeholder="Town/City"
            v-model="town"
            class="mt-5 w-full"
            name="town"

            data-vv-as="Town"
          />
          <span class="text-danger text-sm" v-show="errors.has('town')">{{ errors.first('town') }}</span>

          <vs-input
            label-placeholder="Postcode"
            v-model="postCode"
            class="mt-5 w-full"
            name="postCode"

            data-vv-as="Post Code"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('postCode')"
          >{{ errors.first('postCode') }}</span>
        </div>
        <div class="w-full mt-5">
          <label class="vs-input--label">Account Status</label>
          <vs-switch v-model="status"/>
          {{ status ? 'Active' : 'Inactive' }}

        </div>
        <div class="flex flex-wrap items-center pt-6 w-full md:w-1/2 justify-end">
          <vs-button color="danger" @click="cancelBtn" class="mr-8">Cancel</vs-button>
          <vs-button @click="submitData" :disabled="!isFormValid">Save</vs-button>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import {mapActions} from "vuex";
import _ from "lodash";

export default {
  data() {
    return {
      dataId: null,
      learningCenterId: "",
      directorId: "",
      titleOptions: [
        {label: "Mr.", value: "Mr."},
        {label: "Mrs.", value: "Mrs."},
        {label: "Miss.", value: "Miss."},
      ],
      fullName: "",
      contactNumber: "",
      email: "",
      role: "",
      logo: null,
      image: null,
      new_upload: "no",
      status: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
      streetAddress: "",
      town: "",
      postCode: "",
      roomOptions: [],
      countryOptions: [],
      stateOptions: [],
      roomFilter: {label: "Select room", value: ""},
      titleFilter: {label: "Select Title", value: ""},
      countryFilter: {label: "All", value: "all"},
      stateFilter: {label: "All", value: "all"},
      customError: "",
      oldRoom: '',
    };
  },

  watch: {
    countryFilter(obj) {
      this.stateOptions = obj.states;
    },
  },
  computed: {
    isFormValid() {
      return (
        !this.errors.any() &&
        this.fullName &&
        this.email
      );
    },
    director() {
      return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    ...mapActions("center", [
      "updateDirectorTeacher",
      "imageUpload",
      "getDirectorAllCountries",
      "getDirectorRooms",
      "getDirectorTeacherDetails",
    ]),

    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.image = input.target.files[0];
        this.new_upload = "yes";
        var reader = new FileReader();
        reader.onload = (e) => {
          this.logo = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
    async submitData() {
      await this.$validator.validateAll().then((result) => {
        if (result) {
          let data = new FormData();
          data.append("id", this.dataId);
          data.append("room", this.roomFilter.value);
          data.append("title", this.titleFilter.value);
          data.append("role", this.role);
          data.append("fullName", this.fullName);
          data.append("contactNumber", this.contactNumber);
          data.append("streetAddress", this.streetAddress);
          data.append("town", this.town);
          data.append("postCode", this.postCode);
          data.append("state", this.stateFilter.value);
          data.append("country", this.countryFilter.value);
          data.append("directorId", this.director.id);
          data.append("email", this.email);
          data.append("logo", this.image);
          data.append("new_upload", this.new_upload);
          data.append("oldRoom", this.oldRoom);
          data.append("status", this.status);
          this.customError = "";
          if (this.dataId !== null) {
            this.$vs.loading();
            this.updateDirectorTeacher(data)
              .then(async (res) => {
                if ((await res.status) === 200) {
                  this.$vs.notify({
                    title: 'Success',
                    text: "Teacher updated Successfully",
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'success'
                  });
                  this.$router.push({
                    name: "director-learning-centre-view-list",
                    params: {type: "teachers", id: this.learningCenterId},
                  });

                }
                this.$vs.loading.close();
              })
              .catch((err) => {
                this.$vs.loading.close();
              });
          } else {
            this.$vs.loading();
            this.addDirectorTeacher(data)
              .then((res) => {
                if (res.status === 200) {
                  this.$vs.loading.close();
                  this.$vs.notify({
                    title: 'Success',
                    text: "Teacher added Successfully",
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'success'
                  });
                  this.$router.push({
                    name: "director-learning-centre-view-list",
                    params: {type: "teachers", id: this.learningCenterId},
                  });
                }
              })
              .catch((err) => {
                this.$vs.loading.close();
                if (err.response.status === "422") {

                  this.$vs.notify({
                    title: 'Failure',
                    text: err.response.data.message,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'dander'
                  });
                }
                this.$vs.notify({
                  title: 'Failure',
                  text: "Failed to add teacher",
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'dander'
                });
              });
          }
        }
      });
    },
    cancelBtn() {
      this.$router.push({
        name: "director-teachers-view",
        params: {id: this.dataId},
      });
    },
    async getDetails(id) {
      this.$vs.loading();
      let self = this;
      await this.getDirectorTeacherDetails(id)
        .then(async (res) => {
          let result = await res.data.data;
          self.dataId = result._id;
          if (result.roomDetails.length > 0) {
            self.oldRoom = result.roomDetails[0]._id;
            self.roomFilter = {
              value: result.roomDetails[0]._id,
              label: result.roomDetails[0].name,
            };
          }

          self.titleFilter = {label: result.title, value: result.title};
          self.learningCenterId = result.learningCenterId;
          self.fullName = result.fullName;
          self.role = result.role;
          self.email = result.email;
          self.contactNumber = result.mobileNumber;
          self.image = result.profileImage;
          self.logo = result.profileImage;
          self.status = result.accountStatus === 'Active';
          self.town =
            "undefined" != typeof result.address ? result.address.suburb : "";

          self.postCode =
            "undefined" != typeof result.address ? result.address.postCode : "";
          self.streetAddress =
            "undefined" != typeof result.address ? result.address.street : "";
          if ("undefined" != typeof result.address) {
            self.stateFilter = {
              label: result.address.state,
              value: result.address.state,
            };
            self.countryFilter = {
              label: result.country[0].name,
              value: result.country[0].name,
            };
          } else {
            self.countryFilter = {label: "All", value: "all"};
            self.stateFilter = {label: "All", value: "all"};
          }

          self.getDirectorAllCountries().then(async (res) => {
            let filter = {
              label: self.countryFilter.label,
              value: self.countryFilter.label,
            };
            let selectedCountry = _.find(res.data.data, filter);

            self.countryFilter = selectedCountry;
            self.stateOptions = await self.countryFilter.states;
          });

          self.$vs.loading.close();
        })
        .catch((err) => {
          self.$vs.loading.close();
          //console.error(err)
        });
    },
  },
  created() {
    this.getDirectorAllCountries()
      .then((res) => {
        this.countryOptions = this.$store.state.center.countryOptions;
      })
      .catch((err) => {
      });

    this.getDirectorRooms(this.director.id)
      .then((res) => {
        this.roomOptions = res.data.data;
      })
      .catch((err) => {
      });

    let id = this.$route.params.id;

    if ("undefined" != typeof id) {
      this.getDetails(id);
    }
  },

  components: {
    vSelect,
    // VuePerfectScrollbar,
  },
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
